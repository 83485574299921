import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

export interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
  headerImage?: string;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  image,
  article,
  headerImage: customHeaderImage,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    titleTemplate,
    headerImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    headerImage: customHeaderImage || `${siteUrl}${headerImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet {...{ title, titleTemplate, defaultTitle }}>
      <title>{title}</title>

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.headerImage} />
      <meta property="og:type" content="article" />
      <meta name="twitter:card" content="summary_large_image" />

      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.headerImage && <meta property="og:image" content={seo.headerImage} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description}/>}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}
    </Helmet>
  );
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        headerImage
      }
    }
  }
`;

export default SEO;
